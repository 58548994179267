import React from 'react'

const UserIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
    <g id="Group_8804" data-name="Group 8804" transform="translate(-3278 -8610)">
      <path id="Path_18905" data-name="Path 18905" d="M6,0c3.314,0,6,3.5,6,3.5S9.314,7,6,7,0,3.5,0,3.5,2.686,0,6,0Z" transform="translate(3278 8616)"/>
      <g id="Ellipse_16" data-name="Ellipse 16" transform="translate(3280 8610)" stroke="#fff" stroke-width="1">
        <circle cx="4" cy="4" r="4" stroke="none"/>
        <circle cx="4" cy="4" r="3.5" fill="none"/>
      </g>
    </g>
  </svg>
)

export default UserIcon