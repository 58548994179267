
import React from 'react'

const AddressIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
      <g transform="translate(0)">
        <g data-name="Group 8595">
          <path id="Path_18848" data-name="Path 18848" d="M36.446,0a7.383,7.383,0,0,0-7.5,7.243c0,4.957,6.712,12.233,7,12.541a.692.692,0,0,0,1,0c.286-.307,7-7.584,7-12.541A7.383,7.383,0,0,0,36.446,0Zm0,10.888a3.715,3.715,0,0,1-3.773-3.644,3.776,3.776,0,0,1,7.547,0A3.715,3.715,0,0,1,36.446,10.888Z" transform="translate(-28.946)" fill={props.fill}/>
        </g>
      </g>
    </svg>
  )
}

export default AddressIcon